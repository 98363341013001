import { makeStyles } from '@material-ui/core';

const useStyles = () => makeStyles(() => ({
  paper: {
    backgroundColor: '#fbfbfb',
  },
  box: {
    padding: '.625em 1em',
  },
  title: {
    fontWeight: 700,
    marginBottom: '.5em',
  },
  form: {
    margin: '1em .5em',
  },
  submit: {
    marginTop: '1em',
  },
}))();

export default useStyles;
