import React from 'react';

import { UserCommentWrapper, CommentHeader, CommentBody } from './styled/VirtualVisitCommentsStyles';
import { CommentHeaderContent } from '../Common/Comment/CommentHeaderContent';
import { InnerSpanHTML } from '../Common/InnerHTML';

export const UserComment = ({ comment, fromNow }) => (
  <UserCommentWrapper>
    <CommentHeader>
      <CommentHeaderContent comment={comment} fromNow={fromNow} />
    </CommentHeader>
    <CommentBody>
      <InnerSpanHTML content={comment.body} />
    </CommentBody>
  </UserCommentWrapper>
);
