import React from 'react';

import { useDispatch } from 'react-redux';
import { Box, makeStyles, Typography } from '@material-ui/core';

const imageExtensions = ['jpg', 'gif', 'png', 'jpeg'];
const videoExtensions = ['mp4', '3gp', '3gpp', 'mov'];

const getExtension = (urlString) => {
  try {
    const url = new URL(urlString);
    return url.pathname.substr(url.pathname.lastIndexOf('.') + 1);
  } catch (error) {
    return null;
  }
};

const useStyles = () => makeStyles(() => ({
  root: {
    padding: '.25em .5em 0',
  },
}))();

export const VirtualVisitQuestionAnswer = ({ type, answer }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const openViewer = (images) => {
    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  };

  return (!answer ? '' : (
    <Box className={styles.root}>
      {type === 'TEXT' && (
        <Typography>{answer.answer}</Typography>
      )}
      {type === 'OPTIONS' && (
        <Box className="d-flex justify-content-between">
          <Typography>{answer.protocolQuestionOption.optionText}</Typography>
          <Typography>{answer.protocolQuestionOption.score}</Typography>
        </Box>
      )}
      {type === 'PICTURE' && (
        <Box>
          {!!answer.protocolQuestionAnswerImages.length && imageExtensions.includes(getExtension(answer.protocolQuestionAnswerImages[0].image)) && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            <img height="250" className="cursor-pointer" src={answer.protocolQuestionAnswerImages[0].image} alt="answer" onClick={() => openViewer([answer.protocolQuestionAnswerImages[0].image])} />
          )}
          {!!answer.protocolQuestionAnswerImages.length && videoExtensions.includes(getExtension(answer.protocolQuestionAnswerImages[0].image)) && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video height="250" controls>
              <source src={answer.protocolQuestionAnswerImages[0].image} />
            </video>
          )}
        </Box>
      )}
    </Box>
  ));
};
