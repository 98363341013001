import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography } from '@material-ui/core';

import useStyles from '../../Common/ProtocolCard/styled/ProtocolCardStyles';
import { HighlightedText } from '../styled/HighlightedText';
import { GetFormat } from '../../../utils/functions';

export const VirtualVisitInfoCard = ({ virtualVisit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Box className={classes.box}>
        <Typography component="h6" className={classes.title}>
          {`${t('appointment.scheduled')}: `}
          <HighlightedText>{GetFormat(virtualVisit.appointment, 'DATEFORMAT')}</HighlightedText>
        </Typography>
      </Box>
    </Paper>
  );
};
