import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Link, Paper, Typography } from '@material-ui/core';

import useStyles from './styled/ProtocolCardStyles';
import { HighlightedText } from '../styled/HighlightedText';
import { ProtocolTypeBox } from './ProtocolTypeBox';
import { useApplicationInterface } from '../../../utils/ApplicationInterfaceUtils';

const { appDoctor } = useApplicationInterface();

export const ProtocolCard = ({ protocol }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper className={classes.root}>
      <Box className={classes.box}>
        <Typography component="h6" className={classes.title}>
          {`${t('protocol.used')}: `}
          <Link href={`/protocols/edit/${protocol.uuid}`} underline="none">{protocol.name}</Link>
        </Typography>
        {appDoctor && (
          <>
            <Typography component="h6" className={classes.subtitle}>
              ID:&nbsp;
              <HighlightedText>{protocol.uuid}</HighlightedText>
            </Typography>
            <ProtocolTypeBox protocol={protocol} />
          </>
        )}
      </Box>
    </Paper>
  );
};
