/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Formik, FieldArray, Field as FormikField } from 'formik';
import { Box, Button, DialogActions, Grid, Typography } from '@material-ui/core';
import * as Yup from 'yup';

import { Error, Form, Field, Label } from '../Common/styled/Form';
import { HighlightedText } from '../Common/styled/HighlightedText';
import { Dropzone } from '../Common/Dropzone/Dropzone';
import useStyles from './styled/VirtualVisitMakeStyles';
import { AlertUI } from '../../componentsUI/Alert';
import Loading from '../Common/Loading';

export const VirtualVisitPatientForm = (
  {
    virtualVisit,
    handleSubmit = () => {},
    serverError = false,
    updating = false,
    answering = false,
  },
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [formError, setFormError] = useState(false);

  function openViewer(images) {
    dispatch({ type: 'SET_VIEWER_IMAGES', viewerImages: { viewerImages: images.map((image) => ({ src: image })) } });
    dispatch({ type: 'TOGGLE_VIEWER', showViewer: { showViewer: true } });
  }

  const imageExtensions = ['jpg', 'gif', 'png', 'jpeg'];
  const videoExtensions = ['mp4', '3gp', '3gpp', 'mov'];

  function getExtension(urlString) {
    try {
      const url = new URL(urlString);
      return url.pathname.substr(url.pathname.lastIndexOf('.') + 1);
    } catch (error) {
      return null;
    }
  }

  function getQuestionAnswer(questionUuid) {
    return virtualVisit.protocolQuestionAnswers.find((questionAnswer) => questionAnswer.protocolQuestion.uuid === questionUuid);
  }

  function createAnswer(question) {
    const questionAnswer = getQuestionAnswer(question.uuid);

    return {
      protocolQuestionUuid: question.uuid,
      answer: questionAnswer ? questionAnswer.answer : '',
      protocolQuestionOptionUuid: questionAnswer && questionAnswer.protocolQuestionOption ? questionAnswer.protocolQuestionOption.uuid : null,
    };
  }

  const formSubmit = (form, { setErrors }) => {
    const formErrors = form.answers.filter((answer) => {
      if (answer.answer !== '') return null;
      if (answer.protocolQuestionOptionUuid !== null) return null;
      if (answer.protocolQuestionAnswerImages !== null) return null;
      return true;
    });

    const submitError = formErrors && formErrors[0];
    setFormError(submitError);

    if (!submitError) handleSubmit(form, { setErrors });
  };

  const validationSchema = Yup.object().shape({});

  const initialValues = {
    answers: virtualVisit ? virtualVisit.protocol.questions.map((question) => createAnswer(question)) : [],
  };

  const submitButtonVisitAnswered = { label: t('visit.already.answered'), disabled: true };
  const submitButtonVisitNotAnswered = { label: t('send.answer'), disabled: false };
  const submit = virtualVisit.answered ? submitButtonVisitAnswered : submitButtonVisitNotAnswered;
  const answered = virtualVisit && virtualVisit.answered;

  return (
    <Box className={classes.box}>
      <Typography className={classes.title}>
        {`${t('visit.with.doctor')}: `}
        <HighlightedText>
          {`${virtualVisit.doctor.name} ${virtualVisit.doctor.surname}`}
        </HighlightedText>
      </Typography>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={formSubmit}
      >
        {(props) => (
          <Form autoComplete="off" onSubmit={props.handleSubmit}>

            <FieldArray name="answers">
              {() => (
                <Grid container spacing={2}>
                  {virtualVisit.protocol.questions.map((question, index) => {
                    const questionAnswer = getQuestionAnswer(question.uuid);
                    const errorQuestionUuid = formError && formError.protocolQuestionUuid;
                    const optionMissing = question.uuid === errorQuestionUuid;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid item xs={12} key={index.toString()}>
                        <Label>{question.question}</Label>
                        <Box className={classes.box}>
                          {question.type === 'TEXT' && (
                            <Box>
                              <Field
                                name={`answers[${index}].answer`}
                                value={props.values.answers[index].answer}
                                onChange={props.handleChange}
                                disabled={answered}
                                required
                              />
                              <ErrorMessage name={`answers[${index}].answer`} component={Error} />
                            </Box>
                          )}
                          {question.type === 'OPTIONS' && (
                            <>
                              {question.options.map((option, optionIndex) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Box key={optionIndex} className={classes.radioWrapper}>
                                  <FormikField
                                    type="radio"
                                    name={`answers[${index}].protocolQuestionOptionUuid`}
                                    value={option.uuid}
                                    checked={option.uuid === props.values.answers[index].protocolQuestionOptionUuid}
                                    onChange={props.handleChange}
                                    disabled={answered}
                                    required
                                  />
                                  <Typography component="span" className={classes.radioLabel}>{option.optionText}</Typography>
                                </Box>
                              ))}
                              <ErrorMessage name={`answers[${index}].protocolQuestionOptionUuid`} component={Error} />
                            </>
                          )}
                          {question.type === 'PICTURE' && (
                            <Box className={classes.picture}>
                              {question.helpText && !answered && (
                                <Grid item xs={12}>
                                  <Label className="mr-3">{`${t('instructions')}:`}</Label>
                                  <span>{question.helpText}</span>
                                </Grid>
                              )}
                              {!!question.helpPictures.length && (
                                <Grid item xs={12}>
                                  <Label className="mr-3">{`${t('help.file')}:`}</Label>
                                  {imageExtensions.includes(getExtension(question.helpPictures[0].picture)) && (
                                    <img height="150" className="cursor-pointer" src={question.helpPictures[0].picture} alt="helpPicture" onClick={() => openViewer([question.helpPictures[0].picture])} />
                                  )}
                                  {videoExtensions.includes(getExtension(question.helpPictures[0].picture)) && (
                                    // eslint-disable-next-line jsx-a11y/media-has-caption
                                    <video height="250" controls>
                                      <source src={question.helpPictures[0].picture} />
                                    </video>
                                  )}
                                </Grid>
                              )}
                              <Dropzone
                                accept="image/jpg, image/gif, image/png, image/jpeg, video/mp4, video/3gpp, video/quicktime, application/pdf"
                                setFile={(image) => {
                                  setFormError(null);
                                  props.setFieldValue(`answers[${index}].protocolQuestionAnswerImages[0].image`, image);
                                }}
                                clearFile={() => {
                                  props.setFieldValue(`answers[${index}].protocolQuestionAnswerImages`, null);
                                }}
                                answered={answered}
                                currentFileUrl={
                                  questionAnswer
                                  && questionAnswer.protocolQuestionAnswerImages
                                  && !!questionAnswer.protocolQuestionAnswerImages.length
                                    ? questionAnswer.protocolQuestionAnswerImages[0].image : ''
                                  }
                              />
                            </Box>
                          )}
                          {optionMissing && (
                            <Typography className={classes.warning}>
                              {t('field.missing')}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </FieldArray>

            <Grid container spacing={2}>
              {/* <Grid item xs={12}> */}
              {/*  <Label htmlFor="medicalImages" className={classes.dropzoneLabel}>{t('radiography')}</Label> */}
              {/*  <Dropzone */}
              {/*    disabled={answered} */}
              {/*    setFile={(image) => props.setFieldValue('medicalImages[0].image', image)} */}
              {/*    currentFileUrl={virtualVisit && virtualVisit.medicalImages.length ? virtualVisit.medicalImages[virtualVisit.medicalImages.length - 1].image : ''} /> */}
              {/*  <ErrorMessage name="medicalImages" component={Error} /> */}
              {/* </Grid> */}
              {serverError && (
                <Grid item xs={12}>
                  <AlertUI severity="error" title="Error">{t('server.error')}</AlertUI>
                </Grid>
              )}
              {formError && (
                <Grid item xs={12}>
                  <AlertUI severity="warning" title="Error">{t('there.are.questions.not.answered')}</AlertUI>
                </Grid>
              )}
              {(updating || answering) && <Loading />}
              <Grid item xs={12}>
                <DialogActions>
                  <Button className={classes.button} disabled={submit.disabled} type="submit" color="primary">{submit.label}</Button>
                </DialogActions>
              </Grid>
            </Grid>

          </Form>
        )}
      </Formik>
    </Box>
  );
};
