import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { Typography, makeStyles } from '@material-ui/core';

import { HighlightedText } from '../styled/HighlightedText';
import { GET_HOSPITAL } from '../../../graphql/queries';

const useStyles = () => makeStyles(() => ({
  subtitle: {
    fontSize: '.75em',
  },
}))();

export const HospitalInfo = ({ uuid }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data } = useQuery(
    GET_HOSPITAL, { variables: { uuid } },
  );

  return data && data.hospital ? (
    <Typography component="h6" className={classes.subtitle}>
      {`${t('published.by.hospital')}: `}
      <HighlightedText>
        {data && data.hospital && data.hospital.name}
      </HighlightedText>
    </Typography>
  ) : '';
};
