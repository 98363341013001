import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/react-hooks';
import { Container } from '@material-ui/core';
import { ThemeContext } from 'styled-components';

import { isEmpty } from '../../utils/ObjectUtils';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { PageContent } from '../Common/styled/PageContent';
import useStyles from './styled/VirtualVisitMakeStyles';
import { VirtualVisitDetailView } from './VirtualVisitDetailView';
import { SectionBar } from '../../componentsUI/SectionBar';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { ArticleContainerUI } from '../Common/styled/ArticleContainerUI';
import { CardWrapperUI } from '../Common/styled/CardWrapperUI';
import { parseGraphqlErrors } from '../../utils/FormikUtils';
import { setHospitalEnvironment } from '../../utils/HospitalUtils';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';
import { CREATE_VIRTUAL_VISIT_ANSWERS, UPDATE_VIRTUAL_VISIT } from '../../queries/VirtualVisits/VirtualVisits';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';

const { appDoctor } = useApplicationInterface();

export const VirtualVisitDetail = ({ virtualVisit, errorMessage }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  const currentHospital = useSelector((state) => state.hospital);
  const [serverError, setServerError] = useState(false);
  const language = i18n && i18n.language;

  if (currentHospital && virtualVisit && virtualVisit.patient && currentHospital.uuid !== virtualVisit.patient.hospital.uuid) {
    setHospitalEnvironment({ uuid: virtualVisit.patient.hospital.uuid, dispatch, context });
  }

  const getApplicationPath = (d) => {
    if (isEmpty(d)) return null;

    let patient = null;

    if (appDoctor) {
      patient = {
        name: `${d.patient.name} ${d.patient.surname}`,
        link: `/patient/${d.patient.uuid}`,
      };
    }

    return {
      patient,
      medicalCase: {
        name: d.medicalCase.title,
        link: `/case/${d.medicalCase.uuid}`,
      },
    };
  };

  const [updateVirtualVisit, { loading: updating }] = useMutation(UPDATE_VIRTUAL_VISIT);
  const [answerVirtualVisit, { loading: answering }] = useMutation(
    CREATE_VIRTUAL_VISIT_ANSWERS,
    {
      onCompleted: () => {
        navigate('/virtual-visits').then(
          () => toast(t('virtual.visit.success'), { className: 'toast-success' }),
        );
      },
    },
  );

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      await updateVirtualVisit({
        variables: {
          input: {
            uuid: virtualVisit.uuid,
            medicalImages: values.medicalImages,
          },
        },
      });
      await answerVirtualVisit({
        variables: {
          input: {
            virtualVisitUuid: virtualVisit.uuid,
            answers: values.answers,
            language,
          },
        },
      });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <ArticleContainerUI>
      <Navbar className={classes.subtitle}>
        <SectionBar title="virtual.visit" breadcrumbs={getApplicationPath(virtualVisit)} />
      </Navbar>

      {errorMessage && (
        <AlertWrapperUI>
          <AlertUI severity="error" title="Error">{t(errorMessage)}</AlertUI>
        </AlertWrapperUI>
      )}
      {virtualVisit && (
        <Container maxWidth="lg" className="article">
          <CardWrapperUI>
            <ScrollableContainer padding="1.25em 0">
              <PageContent>
                <VirtualVisitDetailView
                  virtualVisit={virtualVisit}
                  handleSubmit={handleSubmit}
                  serverError={serverError}
                  updating={updating}
                  answering={answering}
                />
              </PageContent>
            </ScrollableContainer>
          </CardWrapperUI>
        </Container>
      )}
    </ArticleContainerUI>
  );
};
