import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';

import useStyles from '../styled/VirtualVisitMakeStyles';
import { Label } from '../../Common/styled/Form';
import { VirtualVisitQuestionAnswer } from './VirtualVisitQuestionAnswer';

export const VirtualVisitQuestions = ({ virtualVisit }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getQuestionAnswer = (questionUuid) => (
    virtualVisit.protocolQuestionAnswers.find((answer) => answer.protocolQuestion.uuid === questionUuid)
  );

  const getQuestions = (questions) => questions.map((question, qi) => {
    const questionAnswer = getQuestionAnswer(question.uuid);
    return (
      <Box key={qi.toString()}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Label>{question.question}</Label>
            <VirtualVisitQuestionAnswer type={question.type} answer={questionAnswer} />
          </Grid>
        </Grid>
      </Box>
    );
  });

  return (
    <Box className={classes.box}>
      <Typography className={classes.title}>
        {`${t('questions')}: `}
      </Typography>
      {getQuestions(virtualVisit.protocol.questions)}
    </Box>
  );
};
