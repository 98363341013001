import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';

import { Error, Form } from '../../Common/styled/Form';
import { Dropzone } from '../../Common/Dropzone/Dropzone';
import useStyles from '../styled/VirtualVisitFormMakeStyles';
import { GET_VIRTUAL_VISITS, UPDATE_VIRTUAL_VISIT } from '../../../queries/VirtualVisits/VirtualVisits';
import { parseGraphqlErrors } from '../../../utils/FormikUtils';
import Loading from '../../Common/Loading';
import { ButtonUI } from '../../../componentsUI/Button';

export const VirtualVisitImageForm = ({ virtualVisit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [serverError, setServerError] = useState(false);


  const [updateVirtualVisit, { loading }] = useMutation(
    UPDATE_VIRTUAL_VISIT,
    {
      onCompleted: () => {
        navigate('/virtual-visits');
      },
      refetchQueries: [{ query: GET_VIRTUAL_VISITS }],
      awaitRefetchQueries: true,
    },
  );

  const handleSubmit = async (values, { setErrors }) => {
    setServerError(false);
    try {
      await updateVirtualVisit({
        variables: {
          input: {
            uuid: virtualVisit.uuid,
            ...values,
          },
        },
      });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const validationSchema = Yup.object().shape({});
  const initialValues = {};
  const currentImageFile = virtualVisit && virtualVisit.medicalImages.length
    ? virtualVisit.medicalImages[virtualVisit.medicalImages.length - 1].image
    : '';

  return (
    <Box className={classes.box}>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {(props) => (
          <Form autoComplete="off" onSubmit={props.handleSubmit} className={classes.form}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Dropzone
                  setFile={(image) => props.setFieldValue('medicalImages[0].image', image)}
                  currentFileUrl={currentImageFile}
                />
                <ErrorMessage name="medicalImages" component={Error} />
              </Grid>
              <Grid item xs={12}>
                {serverError ? <Error>{t('server.error')}</Error> : null}
                {loading && <Loading />}
              </Grid>
              <Grid item xs={12}>
                <ButtonUI
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {t('save')}
                </ButtonUI>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
