import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/MenuLayout';
import { VirtualVisitDetail } from '../../components/VirtualVisits/VirtualVisitDetail';
import { GET_VIRTUAL_VISIT } from '../../queries/VirtualVisits/VirtualVisits';
import Loading from '../../components/Common/Loading';
import { networkErrorParse } from '../../utils/ErrorGraphQLUtils';

export default ({ virtualVisitId }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const { loading, error, data } = useQuery(
    GET_VIRTUAL_VISIT,
    {
      variables: {
        uuid: virtualVisitId,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  if (!errorMessage && error) setErrorMessage(networkErrorParse(error));

  const virtualVisit = data && data.virtualVisit;

  return (
    <Layout>
      {loading && !virtualVisit && !errorMessage
        ? <Loading />
        : <VirtualVisitDetail virtualVisit={virtualVisit} errorMessage={errorMessage} />}
    </Layout>
  );
};
