import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useKeycloak } from 'react-keycloak';
import { useMutation } from '@apollo/react-hooks';
import { Box, Grid, Typography } from '@material-ui/core';
import { v4 as uuidV4 } from 'uuid';
import { CommentsWrapper, CommentBox } from './styled/VirtualVisitCommentsStyles';
import { UserComment } from './UserComment';

import { ADD_VIRTUAL_VISIT_COMMENT } from '../../queries/VirtualVisits/VirtualVisits';
import useStyles from './styled/VirtualVisitFormMakeStyles';
import Loading from '../Common/Loading';
import { ButtonUI } from '../../componentsUI/Button';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';

const { appDoctor } = useApplicationInterface();

export const VirtualVisitComments = ({ virtualVisit }) => {
  const { t } = useTranslation();
  const [keycloak] = useKeycloak();
  const classes = useStyles();
  const [commentText, setCommentText] = useState('');
  const [isSendCommentButtonActive, setIsSendCommentButtonActive] = useState(false);

  const [addMedicalCaseCommentMutation, { loading }] = useMutation(ADD_VIRTUAL_VISIT_COMMENT);

  const postComment = () => {
    if (!isSendCommentButtonActive) {
      return;
    }

    addMedicalCaseCommentMutation({
      variables: {
        input: {
          virtualVisitUuid: virtualVisit.uuid,
          body: commentText,
        },
      },
      optimisticResponse: {
        addMedicalCaseComment: {
          ...virtualVisit,
          comments: [...virtualVisit.comments, {
            __typename: 'Comment',
            uuid: uuidV4(),
            author: {
              __typename: 'User',
              uuid: uuidV4(),
              name: keycloak.tokenParsed.given_name,
              surname: keycloak.tokenParsed.family_name,
              profilePhoto: 'profilePhoto',
            },
            createdAt: new Date(),
            body: commentText,
          }],
        },
      },
    });
    setCommentText('');
  };

  const onQuillChange = ({ content, editor }) => {
    setCommentText(content);
    setIsSendCommentButtonActive(editor.getText().trim() !== '');
  };

  const onQuillKeyDown = (event) => {
    // Post comment if ctrl + intro
    if (event.keyCode === 13 && event.ctrlKey) {
      postComment();
    }
  };

  return (
    <Box className={classes.box}>
      <Box className={classes.form}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <CommentsWrapper>
              {virtualVisit.comments.map((comment) => (
                <UserComment comment={comment} key={comment.uuid} fromNow />
              ))}
              {virtualVisit && virtualVisit.comments && virtualVisit.comments.length === 0
              && <Typography>{t('virtual.visit.no.comments')}</Typography>}
              {loading && <Loading />}
              {appDoctor && (
                <CommentBox>
                  <ReactQuill
                    value={commentText}
                    theme="snow"
                    bounds=".quill"
                    placeholder={t('comment.placeholder')}
                    modules={{
                      toolbar: [
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link'],
                      ],
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                    onChange={(content, delta, source, editor) => { onQuillChange({ content, delta, source, editor }); }}
                    onKeyDown={(event) => { onQuillKeyDown(event); }}
                  />
                </CommentBox>
              )}
            </CommentsWrapper>
          </Grid>
          {appDoctor && (
            <Grid item xs={12} className={classes.submit}>
              <ButtonUI
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={!isSendCommentButtonActive}
                onClick={postComment}
              >
                {t('post.comment')}
              </ButtonUI>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
