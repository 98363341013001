import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import useStyles from '../styled/VirtualVisitMakeStyles';

export const VirtualVisitQuestionsScore = ({ score }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return !score ? '' : (
    <Box className={classes.box}>
      <hr />
      <Box className={classes.score}>
        <Typography>{t('total.score')}</Typography>
        <Typography className={classes.value}>{score}</Typography>
      </Box>
    </Box>
  );
};
