import { makeStyles } from '@material-ui/core';

const useStyles = () => makeStyles((theme) => ({
  root: {
    marginBottom: 0,
  },
  out: {
    marginTop: '0',
    marginBottom: '.25em',
  },
  accordionBox: {
    width: '100%',
    flexGrow: 1,
    padding: '1.25em 0',
  },
  accordion: {
    margin: '-.5em 0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      color: theme.palette.common.black,
      background: theme.palette.primary.light,
    },
  },
  box: {
    padding: '.625em 1em',
    '& .MuiLink-underlineNone:hover': {
      textDecoration: 'none',
    },
    '& > hr': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  title: {
    fontWeight: 700,
    marginBottom: '.5em',
  },
  score: {
    display: 'flex !important',
    justifyContent: 'space-between !important',
    '& > p.MuiTypography-body1': {
      fontWeight: 700,
    },
  },
  value: {
    color: theme.palette.primary.main,
  },
  picture: {
    marginBottom: 6,
  },
  dropzoneLabel: {
    display: 'block',
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
    border: `1px solid ${theme.palette.primary.main}30`,
  },
  radioWrapper: {
    marginBottom: '.3125em',
  },
  radioLabel: {
    paddingLeft: '.4375em',
  },
  warning: {
    fontSize: '.9375em',
    color: '#e76400d7',
  },
}))();

export default useStyles;
