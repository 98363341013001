import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Paper } from '@material-ui/core';

import { VirtualVisitComments } from './VirtualVisitComments';
import { ProtocolCard } from '../Common/ProtocolCard/ProtocolCard';
import { VirtualVisitQuestions } from './question/VirtualVisitQuestions';
import useStyles from './styled/VirtualVisitMakeStyles';
import { VirtualVisitQuestionsScore } from './question/VirtualVisitQuestionsScore';
import { VirtualVisitImageForm } from './image/VirtualVisitImageForm';
import InfoAccordion from '../../componentsUI/InfoAccordion';
import { VirtualVisitInfoCard } from '../Common/VirtualVisitCard/VirtualVisitInfoCard';
import { VirtualVisitPatientForm } from './VirtualVisitPatientForm';
import { useApplicationInterface } from '../../utils/ApplicationInterfaceUtils';

const { appDoctor } = useApplicationInterface();

const getSumScores = (total, element) => {
  const score = element && element.protocolQuestionOption ? element.protocolQuestionOption.score : 0;
  return total + score;
};

export const VirtualVisitDetailView = (
  {
    virtualVisit,
    handleSubmit = () => {},
    serverError,
    updating = false,
    answering = false,
  },
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const totalScore = virtualVisit.protocolQuestionAnswers.reduce(getSumScores, 0);

  const ArchivesWrapper = ({ children }) => (
    <Grid container spacing={1} className={classes.out}>
      <Grid item xs={12} lg={10} xl={7}>
        <Paper elevation={2}>
          <Box className={classes.accordionBox}>
            <Grid container spacing={1}>
              <Grid item className={classes.accordion}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  const Image = () => (
    <VirtualVisitImageForm virtualVisit={virtualVisit} handleSubmit={handleSubmit} serverError={serverError} />
  );

  const Comments = () => (
    <VirtualVisitComments virtualVisit={virtualVisit} />);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={8}>
        <ProtocolCard protocol={virtualVisit.protocol} />
      </Grid>
      <Grid item xs={12} lg={4}>
        <VirtualVisitInfoCard virtualVisit={virtualVisit} />
      </Grid>
      <Grid item xs={12} lg={10} xl={7}>
        <Paper className={classes.paper}>
          { appDoctor
            ? (
              <VirtualVisitPatientForm
                virtualVisit={virtualVisit}
                handleSubmit={handleSubmit}
                updating={updating}
                answering={answering}
                serverError={serverError}
              />
            )
            : (
              <>
                <VirtualVisitQuestions virtualVisit={virtualVisit} />
                <VirtualVisitQuestionsScore score={totalScore} />
              </>
            )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <ArchivesWrapper>
          {/* {userInterface !== 'patient' && ( */}
          {/*  <InfoAccordion */}
          {/*    title={t('radiography')} */}
          {/*    Item={Image} */}
          {/*    expanded={false} */}
          {/*  /> */}
          {/* )} */}
          <InfoAccordion
            title={t('comments')}
            Item={Comments}
            expanded={virtualVisit && virtualVisit.comments && virtualVisit.comments.length > 0}
          />
        </ArchivesWrapper>
      </Grid>
    </Grid>
  );
};
