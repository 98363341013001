import React from 'react';

import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import useStyles from './styled/ProtocolCardStyles';
import { HighlightedText } from '../styled/HighlightedText';
import { getProtocolStateLabel } from '../../../utils/ProtocolUtils';
import { HospitalInfo } from '../Hospital/HospitalInfo';

export const ProtocolTypeBox = ({ protocol }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography component="h6" className={classes.subtitle}>
        {`${t('type')}: `}
        <HighlightedText>
          {`${t(getProtocolStateLabel(protocol.state))} `}
        </HighlightedText>
      </Typography>
      {protocol && protocol.state && protocol.state === 3 && (
        <HospitalInfo uuid={protocol.hospitalUuid} />
      )}
    </>
  );
};
