import { makeStyles } from '@material-ui/core';

const useStyles = () => makeStyles(() => ({
  root: {
    marginBottom: 0,
  },
  box: {
    padding: '.625em 1em',
    '& .MuiLink-underlineNone:hover': {
      textDecoration: 'none',
    },
  },
  title: {
    fontWeight: 700,
  },
  subtitle: {
    fontSize: '.75em',
  },
}))();

export default useStyles;
